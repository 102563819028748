import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import goodsInfoCell from './../tableCellCom/goodsInfoCell.vue';
export default () => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '申请开票编号',
      prop: 'invoiceOutputNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 166,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 206,
      value: '',
      type: 'input',
    },
    {
      label: '发票品类',
      prop: 'invoiceVariety',
      propDesc: 'invoiceVarietyDesc',
      minWidth: 130,
      value: '',
      type: 'signSelect',
      options: 'invoice_variety',
    },
    {
      label: '开票类型',
      prop: 'invoiceType',
      propDesc: 'invoiceTypeDesc', // 需要转换的列值的属性名
      value: '',
      type: 'signSelect',
      options: 'invoice_output_type',
      minWidth: 130,
    },
    {
      label: '税额',
      prop: 'taxRate',
      propDesc: 'taxRateDesc',
      minWidth: 120,
      value: '',
      type: 'signSelect',
      options: [
        {
          label: '6%',
          value: 6,
        },
        {
          label: '9%',
          value: 9,
        },
      ],
    },
    {
      label: '本次开票金额',
      prop: 'totalAmount',
      propDesc: 'totalAmountDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['totalAmountMinVal', 'totalAmountMaxVal'],
    },
    {
      label: '购买方',
      prop: 'buyerName',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '纳税人识别号',
      prop: 'buyerTaxPayerNumber',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '地址/电话',
      prop: 'buyerContactsInfo',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '开户行及账号',
      prop: 'buyerBankInfo',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '货物信息',
      prop: 'recordVoList',
      minWidth: 100,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
          <goodsInfoCell :row="row"></goodsInfoCell>
        `,
        components: { goodsInfoCell },
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',

      options: 'invoice_output_audit_status',
    },
    {
      label: '申请人',
      prop: 'creator',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '申请时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
  ];
  return { columnList, tableRef };
};
