
import { defineComponent, reactive, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useLogListData';
import router from '@/router';
import useVisible from '@/hooks/useVisible';
export default defineComponent({
  name: 'invoiceLog',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { visible, showVisible, closeVisible } = useVisible();
    const data = reactive({
      rowData: null,
      outputAuditId: null,
      detailsData: null,
    });
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 返回
    const back = () => {
      router.push('/erp/financial/invoice');
    };
    const view = (row) => {
      data.outputAuditId = row.id;
      data.detailsData = row;
      showVisible();
    };
    // 查看详情
    const rowClick = () => {
      return true;
    };

    return {
      ...toRefs(data),
      cancel,
      visible,
      showVisible,
      closeVisible,
      view,
      back,
      columnList,
      tableRef,
      refreshTable,
      rowClick,
    };
  },
});
