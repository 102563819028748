<template>
  <div>
    <span class="view-goodsinfo" @click="openDialog(row)" v-if="row.recordVoList && row.recordVoList.length > 0">查看</span>
    <span v-else>-</span>
    <el-dialog v-model="elDialogShow" v-if="elDialogShow" :append-to-body="true" custom-class="invoice-batch-dialog" title="货物信息" width="790px">
      <goods-info :productList="row.recordVoList" :taxAmountDesc="row.taxAmountDesc" :invoiceVariety="invoiceVariety"></goods-info>
    </el-dialog>
  </div>
</template>
<script>
import goodsInfo from '../components/goodsInfo.vue';
export default {
  components: { goodsInfo },
  props: ['row'],
  data() {
    return {
      elDialogShow: false,
      invoiceVariety: null, // 1货物发票   2服务发票
    };
  },

  methods: {
    openDialog(row) {
      this.elDialogShow = true;
      this.invoiceVariety = row.invoiceVariety;
    },
  },
};
</script>
<style lang="scss" scoped>
.view-goodsinfo {
  width: 100%;
  text-align: center;
  color: #2878ff;
  font-size: 14px;
  cursor: pointer;
}
</style>
