<template>
  <div class="invoice-goodsinfo">
    <el-table border :show-summary="summary" :summary-method="summaryFn" class="egrid erp-table__list erp-view__table" max-height="450" :data="productList">
      <el-table-column label="货物" align="center" class-name="no-pd" show-overflow-tooltip width="180">
        <template #default="scope">
          <div>{{ filterName(scope.row.item) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="规格型号" align="center" class-name="no-pd" width="120" show-overflow-tooltip>
        <template #default="scope">
          <div>{{ filterName(scope.row.specification) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单位" align="center" width="90">
        <template #default="scope">
          <div>{{ filterName(scope.row.unit) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="数量" align="center" show-overflow-tooltip width="110">
        <template #default="scope">
          <div>{{ filterName(scope.row.quantityDesc) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="含税金额" align="center" show-overflow-tooltip width="120">
        <template #default="scope">
          <div>{{ filterName(scope.row.totalTaxAmountDesc) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="税额" align="center" show-overflow-tooltip>
        <template #default>
          <div v-if="Number(invoiceVariety) === 1"></div>
          <div v-if="Number(invoiceVariety) === 2 || Number(invoiceVariety) === 3">{{ taxAmountDesc }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    productList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    taxAmountDesc: {
      type: [String, Number],
      default: '',
    },
    invoiceVariety: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    summary() {
      return this.productList.length > 0 && Number(this.invoiceVariety) === 1;
    },
  },
  methods: {
    summaryFn() {
      return ['价税合计', '', '', '', '', this.taxAmountDesc];
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-goodsinfo {
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 30px;
  padding-top: 10px;
}
</style>
